import { FetchBaseQueryError } from '@reduxjs/toolkit/query';

import {
  TableManagerResponse,
  TagType,
  RelationOption,
  RelationOptionsMap,
  ForeignKey,
} from 'src/shared/types';
import { api } from 'src/store/api/api';

type ModelOptionsResult = {
  field: string;
  modelOptions: {
    data: RelationOption[];
  };
};

const tableManagerApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getTableData: builder.query<
      TableManagerResponse,
      {
        apiEndpoint: string;
        modelName: TagType;
        pageSize: string;
        page: string;
        filters: {
          search: string;
        };
      }
    >({
      query: ({ pageSize, page, apiEndpoint, filters }) => ({
        url: `/admin/${apiEndpoint}`,
        method: 'GET',
        params: {
          pageSize,
          page,
          filters,
        },
      }),

      providesTags: (result, error, { modelName }) => {
        return [{ type: modelName }];
      },

      transformResponse: (response: TableManagerResponse) => response,
    }),

    createTableRow: builder.mutation<
      unknown,
      {
        apiEndpoint: string;
        body: unknown;
        modelName: TagType;
        invalidatesTags?: TagType[];
      }
    >({
      query: ({ apiEndpoint, body }) => ({
        url: `/admin/${apiEndpoint}`,
        method: 'POST',
        body,
      }),
      async onQueryStarted({ modelName, invalidatesTags }, { queryFulfilled, dispatch }) {
        const invalidatesTagsData = invalidatesTags
          ? invalidatesTags?.map((tag) => ({ type: tag }))
          : [modelName];
        try {
          await queryFulfilled;
          dispatch(tableManagerApi.util.invalidateTags(invalidatesTagsData));
        } catch {
          dispatch(tableManagerApi.util.invalidateTags(invalidatesTagsData));
        }
      },
    }),

    editTableRow: builder.mutation<
      unknown,
      {
        id: string;
        apiEndpoint: string;
        body: unknown;
        modelName: TagType;
        invalidatesTags?: TagType[];
      }
    >({
      query: ({ id, apiEndpoint, body }) => ({
        url: `/admin/${apiEndpoint}/${id}`,
        method: 'PATCH',
        body,
      }),

      async onQueryStarted({ modelName, invalidatesTags }, { queryFulfilled, dispatch }) {
        const invalidatesTagsData = invalidatesTags
          ? invalidatesTags?.map((tag) => ({ type: tag }))
          : [modelName];

        try {
          await queryFulfilled;

          dispatch(tableManagerApi.util.invalidateTags(invalidatesTagsData));
        } catch {
          dispatch(tableManagerApi.util.invalidateTags(invalidatesTagsData));
        }
      },
    }),

    deleteTableRow: builder.mutation<
      unknown,
      {
        id: string;
        apiEndpoint: string;
        modelName: TagType;
        invalidatesTags?: TagType[];
      }
    >({
      query: ({ id, apiEndpoint }) => ({
        url: `admin/${apiEndpoint}/${id}`,
        method: 'DELETE',
      }),
      async onQueryStarted({ modelName, invalidatesTags }, { queryFulfilled, dispatch }) {
        const invalidatesTagsData = invalidatesTags || [modelName];
        try {
          await queryFulfilled;

          dispatch(tableManagerApi.util.invalidateTags(invalidatesTagsData));
        } catch {
          dispatch(tableManagerApi.util.invalidateTags(invalidatesTagsData));
        }
      },
    }),
    getRelationOptions: builder.query<
      RelationOptionsMap,
      {
        models: ForeignKey[];
        filters?: Record<string, Record<string, string>>;
        invalidatesTags?: TagType[];
      }
    >({
      queryFn: async ({ models, filters }, _queryApi, _extraOptions, baseQuery) => {
        try {
          const results: ModelOptionsResult[] = await Promise.all(
            models.map(async ({ model, field }) => {
              const searchParams = filters?.[field]
                ? `?${new URLSearchParams(filters[field]).toString()}`
                : '';

              const result = await baseQuery(`/admin/${model}/relation-options${searchParams}`);

              return {
                field,
                modelOptions: result?.data as { data: RelationOption[] },
              };
            }),
          );

          const relationOptions: RelationOptionsMap = {};

          results.forEach(({ field, modelOptions }) => {
            relationOptions[field] = modelOptions.data;
          });

          return { data: relationOptions };
        } catch (error) {
          return { error: error as FetchBaseQueryError };
        }
      },
      providesTags: (result, error, { invalidatesTags }) => {
        const invalidatesTagsData = invalidatesTags
          ? invalidatesTags?.map((tag) => ({ type: tag, id: tag }))
          : [];
        return invalidatesTagsData;
      },
    }),
  }),
});

export const {
  useGetTableDataQuery,
  useDeleteTableRowMutation,
  useEditTableRowMutation,
  useCreateTableRowMutation,
  useGetRelationOptionsQuery,
} = tableManagerApi;
