import dayjs from 'dayjs';
import { FC, useEffect, useId, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Checkbox, FormLabel } from '@mui/material';
import { Icons } from 'src/assets/icons';
import {
  REPORT_TABLE_BOTTOM_SPACE,
  REPORT_TABLE_PAGINATION_HEIGHT,
} from 'src/pages/activities/helpers';
import {
  ActivityHealth,
  AllActivityStates,
  EnumActivityTrack,
  PageSize,
  SortOrder,
} from 'src/shared/types';
import { Button } from 'src/shared/ui/button';
import { Filters } from 'src/shared/ui/filters';
import { FilterSelect } from 'src/shared/ui/filterselect';
import { IconButton } from 'src/shared/ui/iconButton';
import { Table, TableHeaderType } from 'src/shared/ui/table';
import { Tag } from 'src/shared/ui/tag';
import { DATE_FORMAT } from 'src/shared/utils';
import { exportToExcel } from 'src/shared/utils/excel';
import { RootState } from 'src/store';
import { useGetAllActivityStatesProcessesFiltersQuery } from 'src/store/api/activities';

interface TableReportByActivityStatesProps {
  isSkip: boolean;
  onTotalChange: (total: number, statesCount: number) => void;
}

const TableReportByActivityStates: FC<TableReportByActivityStatesProps> = ({
  isSkip,
  onTotalChange,
}) => {
  const [isFilterMenuOpen, setIsFilterMenuOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState<PageSize>(15);
  const [isDownloadAllClicked, setIsDownloadAllClicked] = useState(false);

  const handlePageSizeChange = (size: PageSize) => {
    if (currentPage !== 1) {
      setCurrentPage(1);
    }

    setPageSize(size);
  };

  const [sortSettings, setSortSettings] = useState<{
    key: keyof AllActivityStates['allActivities'][number] | null;
    order: SortOrder;
  }>({
    key: null,
    order: SortOrder.ASC,
  });

  const handleChangeSortSettings = (newSort: {
    key: keyof AllActivityStates['allActivities'][number] | null;
    order: SortOrder;
  }) => {
    if (currentPage !== 1) {
      setCurrentPage(1);
    }
    setSortSettings(newSort);
  };

  const boardFilters = useSelector((state: RootState) => state.config.boardFilters);

  const toggleFilterMenu = () => setIsFilterMenuOpen((prev) => !prev);

  const appliedFiltersAmount = Object.values(boardFilters).filter(
    (filter) => filter.length > 0,
  ).length;

  const { data, isLoading, isFetching, isError } = useGetAllActivityStatesProcessesFiltersQuery(
    {
      pagination: {
        page: `${currentPage}`,
        pageSize: `${pageSize}`,
      },
      filters: {
        health: boardFilters.health?.length ? (boardFilters.health as ActivityHealth[]) : undefined,
        track: boardFilters.status?.length
          ? (boardFilters.status as EnumActivityTrack[])
          : undefined,
        jobId: boardFilters.jobID?.length ? boardFilters.jobID : undefined,
        provider: boardFilters.provider?.length ? boardFilters.provider : undefined,
        providerTeam: boardFilters.crew?.length ? boardFilters.crew : undefined,
        delayCategory: boardFilters.delayCategory?.length ? boardFilters.delayCategory : undefined,
      },
      sorting: sortSettings,
    },
    {
      refetchOnMountOrArgChange: true,
      skip: isSkip,
    },
  );

  const {
    data: dataAll,
    isLoading: isLoadingAll,
    isFetching: isFetchingAll,
    isError: isErrorAll,
  } = useGetAllActivityStatesProcessesFiltersQuery(
    {
      filters: {
        health: boardFilters.health?.length ? (boardFilters.health as ActivityHealth[]) : undefined,
        track: boardFilters.status?.length
          ? (boardFilters.status as EnumActivityTrack[])
          : undefined,
        jobId: boardFilters.jobID?.length ? boardFilters.jobID : undefined,
        provider: boardFilters.provider?.length ? boardFilters.provider : undefined,
        providerTeam: boardFilters.crew?.length ? boardFilters.crew : undefined,
        delayCategory: boardFilters.delayCategory?.length ? boardFilters.delayCategory : undefined,
      },
    },
    {
      refetchOnMountOrArgChange: true,
      skip: isSkip || !isDownloadAllClicked,
    },
  );

  useEffect(() => {
    if (data) {
      onTotalChange(data.totalActivities, data.pagination.total);
    }
  }, [data]);

  useEffect(() => {
    if (currentPage !== 1) {
      setCurrentPage(1);
    }
  }, [boardFilters]);

  const renderFilters = data?.filters.map((filter) => (
    <FilterSelect
      key={`ActivityFilter-${filter.label}`}
      options={filter.options.map((option) => ({
        label: option,
        value: option,
      }))}
      label={filter.label}
      placeholder={`Filter by ${filter.label}`}
      id={filter.label}
      type="board"
    />
  ));

  const headers: TableHeaderType<AllActivityStates['allActivities'][number]>[] = useMemo(
    () => [
      {
        title: 'State ID',
        field: 'StateID',
        className: 'w-max',
        canSort: true,
        render: (job) => job.id || '',
      },
      {
        title: 'Activity Name',
        field: 'ActivityName',
        className: 'w-max',
        canSort: true,
        render: (job) => job.name || '',
      },
      {
        title: 'Reported By',
        field: 'ReportedBy',
        className: 'w-max',
        canSort: true,
        render: (job) => job.reportedBy || '',
      },
      {
        title: 'Job Number',
        field: 'JobNumber',
        className: 'w-max',
        canSort: true,
        render: (job) => job.jobNumber || '',
      },
      {
        title: 'Job Name',
        field: 'JobName',
        className: 'w-max',
        canSort: true,
        render: (job) => job.jobName || '',
      },
      {
        title: 'Activity ID',
        field: 'ActivityID',
        className: 'w-max',
        canSort: true,
        render: (job) => job.activityId || '',
      },
      {
        title: 'Progress',
        field: 'Progress',
        className: 'w-max',
        canSort: true,
        render: (job) => job.progress || 0,
      },
      {
        title: 'Provider Name',
        field: 'ProviderName',
        className: 'w-max',
        canSort: true,
        render: (job) => job.provider?.ProviderName || '',
      },
      {
        title: 'Provider ID',
        field: 'ProviderID',
        className: 'w-max',
        canSort: true,
        render: (job) => job.providerId || '',
      },
      {
        title: 'Provider Team Name',
        field: 'ProviderTeamName',
        className: 'w-max',
        canSort: true,
        render: (job) => job.providerTeam?.name || '',
      },
      {
        title: 'Provider Team ID',
        field: 'ProviderTeamID',
        className: 'w-max',
        canSort: true,
        render: (job) => job.providerTeamId || '',
      },
      {
        title: 'Equipment',
        field: 'Equipment',
        className: 'w-max',
        canSort: true,
        render: (job) => job.equipment || '',
      },
      {
        title: 'Equipment Type',
        field: 'EquipmentType',
        className: 'w-max',
        canSort: true,
        render: (job) => job.equipmentTypeId || '',
      },
      {
        title: 'Health',
        field: 'Health',
        className: 'w-max',
        canSort: true,
        render: (job) => job.health || '',
      },
      {
        title: 'Status',
        field: 'Status',
        className: 'w-max',
        canSort: true,
        render: (job) => job.track || '',
      },
      {
        title: 'Delay Category',
        field: 'DelayCategory',
        className: 'w-max',
        canSort: true,
        render: (job) => job.delayCategory?.DelayCategory || '',
      },
      {
        title: 'Start',
        field: 'CurrentStartDate',
        className: 'w-max',
        canSort: true,
        render: (job) =>
          job.startDate
            ? dayjs
                .utc(job.startDate)
                .tz(job.activity.OwnerLocation.LocationTimezone)
                .format(DATE_FORMAT.MM_DD_YYYY_HH_MM)
            : '',
      },
      {
        title: 'End',
        field: 'endDate',
        className: 'w-max',
        canSort: true,
        render: (job) =>
          job.endDate
            ? dayjs
                .utc(job.endDate)
                .tz(job.activity.OwnerLocation.LocationTimezone)
                .format(DATE_FORMAT.MM_DD_YYYY_HH_MM)
            : '',
      },
      {
        title: 'Duration',
        field: 'Duration',
        className: 'w-max',
        render: (job) =>
          job.startDate && job.endDate
            ? dayjs
                .duration(dayjs(job.endDate).diff(dayjs(job.startDate)))
                .format(DATE_FORMAT.HOURS_MINUTES)
            : 0,
      },
    ],
    [],
  );

  const total = useMemo(() => data?.pagination.total || 0, [data]);

  const handleExportToExcel = async () => {
    if (!data?.allActivities) {
      return;
    }

    let dataToExport = data;

    if (isDownloadAllClicked && !dataAll?.allActivities) {
      return;
    }

    if (isDownloadAllClicked && dataAll?.allActivities) {
      dataToExport = dataAll;
    }

    const excelData = dataToExport.allActivities.map((activityState) => {
      const {
        name = '',
        reportedBy = '',
        jobName = '',
        jobNumber = '',
        activityId = '',
        progress = 0,
        provider = { ProviderName: '' },
        delayCategory,
        providerId = '',
        providerTeamId = '',
        equipment = '',
        equipmentTypeId = '',
        health = '',
        track = '',
        startDate = '',
        endDate = '',
        id = '',
      } = activityState || {};

      const providerTeamName = activityState.providerTeam?.name || '';

      const ownerLocationTimeZone =
        activityState.activity.OwnerLocation.LocationTimezone || dayjs.tz.guess();

      const delayCategoryData = delayCategory || { DelayCategory: '', DelayCategoryID: '' };

      return {
        StateID: id,
        ActivityName: name,
        ReportedBy: reportedBy,
        JobNumber: jobName,
        JobName: jobNumber,
        ActivityID: activityId,
        Progress: progress,
        ProviderName: provider.ProviderName,
        ProviderID: providerId,
        ProviderTeamName: providerTeamName,
        ProviderTeamID: providerTeamId,
        Equipment: equipment,
        EquipmentType: equipmentTypeId,
        Health: health,
        Status: track,
        DelayCategory: delayCategoryData.DelayCategory,
        DelayCategoryID: delayCategoryData.DelayCategoryID,
        ScheduledStartDate: startDate
          ? dayjs.utc(startDate).tz(ownerLocationTimeZone).format(DATE_FORMAT.MM_DD_YYYY_HH_MM)
          : '',
        ScheduledEndDate: endDate
          ? dayjs.utc(endDate).tz(ownerLocationTimeZone).format(DATE_FORMAT.MM_DD_YYYY_HH_MM)
          : '',
        ScheduledDuration:
          startDate && endDate
            ? dayjs
                .duration(dayjs(endDate).diff(dayjs(startDate)))
                .format(DATE_FORMAT.HOURS_MINUTES)
            : 0,
      };
    });

    exportToExcel(excelData, 'Activity States');
  };

  const containerRef = useRef<HTMLDivElement | null>(null);
  const [minViewSpace, setMinViewSpace] = useState(0);

  useEffect(() => {
    if (containerRef.current) {
      const offsetTop = containerRef.current.getBoundingClientRect().top;
      setMinViewSpace(offsetTop + REPORT_TABLE_BOTTOM_SPACE + REPORT_TABLE_PAGINATION_HEIGHT);
    }
  }, [containerRef]);

  const downloadAllId = useId();

  return (
    <div className="flex flex-col gap-4">
      <div className="flex gap-4 self-end">
        <div className="flex gap-1 items-center">
          <FormLabel
            htmlFor={downloadAllId}
            className="!text-textColor-primary !font-semibold"
          >
            Download All
          </FormLabel>

          <Checkbox
            checked={isDownloadAllClicked}
            onChange={(_, checked) => setIsDownloadAllClicked(checked)}
            sx={{ '& .MuiSvgIcon-root': { fontSize: 32 } }}
            id={downloadAllId}
          />

          <Button
            size="lg"
            variant="filled"
            endIcon={<Icons.Filled.Files.DownloadIcon fill="#231F20" />}
            className="border-[#636874] flex !gap-3 !p-0"
            onClick={handleExportToExcel}
            disabled={isFetching || isLoadingAll || isFetchingAll}
          >
            Excel
          </Button>
        </div>

        <div className="flex gap-2">
          <div className="relative">
            <IconButton
              size="none"
              iconSize="md"
              color="basic"
              className="h-[48px] w-[48px]"
              onClick={toggleFilterMenu}
            >
              <Icons.Outlined.Controls.FilterIcon />
            </IconButton>

            {!!appliedFiltersAmount && (
              <Tag
                type="white"
                className="absolute right-[-2px] top-0 !px-1"
              >
                {appliedFiltersAmount}
              </Tag>
            )}
          </div>

          {isFilterMenuOpen && (
            <Filters
              title="Filters"
              isOpen={isFilterMenuOpen}
              closeMenu={setIsFilterMenuOpen}
              type="board"
              className="w-[calc(100%-30px)] right-[15px] md:w-auto md:right-[60px] top-[85px] md:min-w-[450px] md:max-w-[450px] z-[9999]"
            >
              {renderFilters}
            </Filters>
          )}
        </div>
      </div>

      <div ref={containerRef}>
        <Table
          headers={headers}
          data={data?.allActivities || []}
          isFetching={isFetching}
          isLoading={isLoading}
          sortSettings={sortSettings}
          onSort={handleChangeSortSettings}
          withIndicator
          variant="secondary"
          containerClassName="h-full"
          heightTable={minViewSpace}
          sticky
          scroll
          isError={isError || isErrorAll}
          pagination={{
            currentPage,
            onPageChange: (page: number) => setCurrentPage(page),
            onPageSizeChange: handlePageSizeChange,
            pageSize,
            total,
          }}
        />
      </div>
    </div>
  );
};

export { TableReportByActivityStates };
