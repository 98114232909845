import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQuery } from './baseQuery';
import { ActivityFilters } from 'src/shared/types';

type BoardFilters = {
  owner?: string[];
  ownerSite?: string[];
  jobCategory?: string[];
  serviceLine?: string[];
  providerArea?: string[];
  providerRegion?: string[];
  providerBranch?: string[];
  division?: string[];
  startDate?: string;
  endDate?: string;
  formType?: string[];
  completedBy?: string[];
} & ActivityFilters;

type SidebarFilters = {
  providerAreaSidebar?: string[];
  providerRegionSidebar?: string[];
  providerBranchSidebar?: string[];
  divisionSidebar?: string[];
  equipmentTypeSidebar?: string[];
};

const api = createApi({
  reducerPath: 'api',
  tagTypes: [
    'Jobs',
    'Tickets',
    'People',
    'Equipment',
    'Configuration',
    'Auth',
    'Access',
    'OwnerLocations',
    'Owners',
    'ServiceLines',
    'ProviderDivisions',
    'ProviderBranches',
    'ContractList',
    'JobCategory',
    'Survey',
    'SurveyAnswer',
    'Provider',
    'Notification',
    'ProviderRoleMatrix',
    'ProviderPersonnel',
    'SurveyNotification',
    'Inspector',
    'Inspectors',
    'Activities',
    'ProviderTeamActivities',
    'Visits',
    'Clients',
    'Assignments',
    'Projects',
  ],
  baseQuery,
  endpoints: () => ({}),
});

export { api };
export type { BoardFilters, SidebarFilters };
