import { FC } from 'react';
import clsx from 'clsx';

import {
  TypeNotation,
  TableManagerFieldValue,
  RelationOptionsMap,
  MetadataMap,
} from 'src/shared/types';

import { FormField } from '../FormField/index';

interface FormFieldsProps {
  schema: TypeNotation[];
  formState: Record<string, TableManagerFieldValue>;
  idField: string;
  isLoadingForm: boolean;
  relationOptions: RelationOptionsMap;
  fieldsMetadata: MetadataMap;
  onChange: (field: string, value: TableManagerFieldValue) => void;
  selectedRow: Record<string, TableManagerFieldValue> | null;
}

const FormFields: FC<FormFieldsProps> = ({
  schema,
  formState,
  idField,
  isLoadingForm,
  relationOptions,
  fieldsMetadata,
  selectedRow,
  onChange,
}) => {
  const isUpdateMode = !!selectedRow;

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
      {schema.map((schemaElement) => {
        const typeMetadata = isUpdateMode ? 'update' : 'create';

        if (
          fieldsMetadata?.[typeMetadata]?.[schemaElement.field] &&
          fieldsMetadata?.[typeMetadata]?.[schemaElement.field]?.hidden
        ) {
          return null;
        }
        return (
          <div
            key={schemaElement.field}
            className={clsx(schemaElement.type === 'array' && 'col-span-2')}
          >
            <FormField
              schemaElement={schemaElement}
              value={formState[schemaElement.field]}
              onChange={onChange}
              isLoadingForm={isLoadingForm}
              requiredState={
                isUpdateMode
                  ? fieldsMetadata.update?.[schemaElement.field]?.requiredState
                  : fieldsMetadata.create?.[schemaElement.field]?.requiredState
              }
              relationOptions={relationOptions}
              fieldsMetadata={fieldsMetadata}
            />
          </div>
        );
      })}
    </div>
  );
};

export { FormFields };
